<template>
  <div class="system-role mt-6" v-if="!isLoading">
    <div class="flex gap-5">
      <div class="flex-1">
        <span class="mb-1 text-sm text-ems-gray500"
          ><span class="text-ems-error inline-block mr-1">*</span
          >{{ t('role-group.name') }}</span
        >
        <a-input
          type="text"
          v-model:value="formState.name"
          :placeholder="t('role-group.name')"
          class="ant-input-sm"
          :disabled="action === ACTION.VIEW"
          :maxlength="50"
          show-count
        />
        <span v-if="errName" class="block mt-1 text-sm text-ems-main2">{{
          errName
        }}</span>
      </div>
      <div class="flex-1">
        <span class="mb-1 text-sm text-ems-gray500">{{
          t('role-group.desc')
        }}</span>
        <a-input
          type="text"
          v-model:value="formState.desc"
          :placeholder="t('role-group.desc')"
          class="ant-input-sm"
          :disabled="action === ACTION.VIEW"
          :maxlength="200"
          show-count
        />
        <span v-if="errDesc" class="block mt-1 text-sm text-ems-main2">{{
          errDesc
        }}</span>
      </div>
      <div class="flex-1">
        <span class="mb-1 text-sm text-ems-gray500 block">{{
          t('role-group.status')
        }}</span>
        <a-select
          dropdown-class-name="select-modal"
          :options="statusOptions"
          :label-prop="'label'"
          :value-prop="'value'"
          v-model:value="formState.status"
          :placeholder="t('role-group.status')"
          class="ant-input-sm mr-4"
          type="text"
          :disabled="action === ACTION.VIEW"
        />
      </div>
    </div>

    <div class="pt-[30px] border-b-1 border-solid border-ems-tag1">
      <a-tabs v-model:activeKey="activeTab">
        <a-tab-pane v-for="item in tabs" :key="item.key" :tab="item.tabName">
        </a-tab-pane>
      </a-tabs>
    </div>
    <div class="w-full h-full !mb-6 flex-1 mt-[30px]">
      <span class="text-base text-ems-gray200 font-semi-bold">
        {{ t('role-group.select-function') }}
      </span>
      <TreeData
        :tree-data="data"
        :selectedData="selectedData"
        class="!mt-4 h-[300px] overflow-y-auto"
        :disabled="action === ACTION.VIEW"
        @onCheck="onCheck"
      />
    </div>
  </div>
  <cds-loading v-if="isLoading" />
</template>
<script setup>
import { computed, defineEmits, ref } from 'vue';
import { i18n } from '@/main';
import TreeData from './TreeData.vue';
import { useStore } from 'vuex';
import { ACTION } from '@/config/Constant.js';
const { t } = i18n.global;
const { state, dispatch } = useStore();
const isLoading = computed(() => state.roleGroup.loading);
const statusOptions = [
  {
    label: t('role-group.active'),
    value: 1,
  },
  {
    label: t('role-group.inactive'),
    value: 0,
  },
];
const allMenusEms = computed(() => state.menu.menuEms || []);
const allMenusVms = computed(() => state.menu.menuVms || []);
const allGroups = computed(() => state.roleGroup.treeGroup || []);
const allCameras = computed(() => state.roleGroup.listTreeCamera || []);
const camerasInGroup = ref([]);

const addKey = (data) => {
  for (let item of data) {
    item.key = item.id;
    if (item.children && item.children.length > 0) {
      addKey(item.children);
    }
  }
};
const addKeyCameraGroup = (data) => {
  for (let item of data) {
    item.key = item.id;
    if (item.children && item.children.length > 0) {
      addKey(item.children);
    }
    if (item.cameras && item.cameras.length > 0) {
      item.cameras.map((cameras) => {
        camerasInGroup.value.push(cameras);
      });
      addKeyCameraGroup(item.cameras);
    }
  }
};
const data = computed(() => {
  const clonedMenusEms = JSON.parse(JSON.stringify(allMenusEms.value));
  const clonedMenusVms = JSON.parse(JSON.stringify(allMenusVms.value));
  const clonedCamera = JSON.parse(JSON.stringify(allCameras.value));
  addKey(clonedMenusEms);
  addKey(clonedMenusVms);
  addKey(clonedCamera);
  addKey(allGroups.value);
  if (activeTab.value === 'function') return clonedMenusEms;
  if (activeTab.value === 'management-image') return clonedMenusVms;
  if (activeTab.value === 'group-management') return allGroups.value;
  if (activeTab.value === 'camera-management') return clonedCamera;
});
const selectedData = computed(() => {
  if (activeTab.value === 'function') return props.formState.selectedFunction;
  if (activeTab.value === 'management-image')
    return props.formState.selectedImage;
  if (activeTab.value === 'group-management')
    return props.formState.selectedGroup;
  if (activeTab.value === 'camera-management')
    return props.formState.selectedCamera;
});
const emits = defineEmits(['onCheck']);
const onCheck = (checkedKeys) => {
  emits('onCheck', {
    tab: activeTab.value,
    value: checkedKeys.checkedKeys,
    infoNode: checkedKeys.infoNode,
  });
};
const tabs = [
  {
    key: 'function',
    tabName: t('role-group.function'),
  },
  {
    key: 'management-image',
    tabName: t('role-group.management-image'),
  },
  {
    key: 'group-management',
    tabName: t('role-group.group-management'),
  },
  {
    key: 'camera-management',
    tabName: t('role-group.camera-management'),
  },
];
const activeTab = ref('function');
const props = defineProps({
  formState: {
    default: {},
  },
  action: {
    default: 0,
  },
  errName: {
    default: '',
  },
  errDesc: {
    default: '',
  },
  errTreeData: {
    default: false,
  },
});
</script>
<style lang="scss">
.system-role .ant-input-sm input.ant-input {
  background-color: transparent;
  --tw-text-opacity: 1;
  color: rgba(247, 240, 247, var(--tw-text-opacity));
}
.system-role .ant-input-sm input.ant-input::placeholder {
  --tw-text-opacity: 1;
  color: rgba(247, 240, 247, var(--tw-text-opacity));
}
.system-role .ant-select {
  --tw-bg-opacity: 1;
  background-color: rgba(43, 42, 58, var(--tw-bg-opacity));
  border-style: none;
  height: 38px;
  width: 100%;
}
.system-role .ant-select-selector {
  background-color: #2B2A3A !important;
  border: none !important;
  border-radius: 0 !important;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}
.system-role .ant-select-selector .ant-select-selection-search {
  background-color: #2B2A3A !important;
}
.system-role .ant-select-selector .ant-select-selection-placeholder {
  font-size: 1rem;
  line-height: 1.5rem;
  color: #F7F0F7 !important;
}
.system-role .ant-select-selector .ant-select-selection-item {
  font-size: 1rem;
  line-height: 1.5rem;
  --tw-text-opacity: 1;
  color: rgba(233, 233, 233, var(--tw-text-opacity));
}
.system-role .ant-input {
  --tw-bg-opacity: 1;
  background-color: rgba(43, 42, 58, var(--tw-bg-opacity));
  font-size: 1rem;
  line-height: 1.5rem;
  padding-left: 12px;
  padding-right: 12px;
  padding-top: 7px;
  padding-bottom: 7px;
  --tw-text-opacity: 1;
  color: rgba(233, 233, 233, var(--tw-text-opacity));
  border: none !important;
}
.system-role .ant-input::placeholder {
  --tw-text-opacity: 1;
  color: rgba(247, 240, 247, var(--tw-text-opacity));
}
</style>
